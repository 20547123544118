<template>
  <div class="vg_wrapper">
    <el-form ref="mtrbForm" :model="mtrbForm" :rules="rules" label-width="120px" size="mini">
      <add-header @submit="submit('mtrbForm')" @cancel="cancel" :stateFlag="true" :formData="mtrbForm">
      </add-header>
      <el-row>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="材料名称" prop="mtrb_name">
                <el-input maxlength="50" v-model="mtrbForm.mtrb_name" placeholder="请填写材料名称" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="材料说明" prop="mtrb_spec">
                <el-input maxlength="255" type="textarea" :rows="4" v-model="mtrbForm.mtrb_spec" placeholder="请填写材料说明" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="材料类别" prop="mtrb_type">
                <el-select filterable v-model="mtrbForm.mtrb_type" placeholder="请选择材料类别">
                  <el-option v-for="item in mtrbTypeList" :key="item.id" :label="item.label" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="采购价格" prop="mtrb_price">
                <el-input maxlength="9" v-model="mtrbForm.mtrb_price" @input="mtrbForm.mtrb_price = helper.keepTNum1(mtrbForm.mtrb_price)" @blur="mtrbForm.mtrb_price = helper.calcPrice(mtrbForm.mtrb_price, 4, 10000)" placeholder="请填写采购价格" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="供应商简称" prop="supp_id">
                <el-select filterable v-model="mtrbForm.supp_id" placeholder="请选择材料类别">
                  <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="材料单位" prop="mtrb_unit">
                <el-select filterable v-model="mtrbForm.mtrb_unit" placeholder="请选择材料单位">
                  <el-option v-for="item in mtrbUnitList" :key="item.id" :label="item.param1" :value="item.param1">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="增值税率" prop="mtrb_inrate">
                <el-input maxlength="9" v-model="mtrbForm.mtrb_inrate" @input="mtrbForm.mtrb_inrate = helper.keepTNum1(mtrbForm.mtrb_inrate)" @blur="mtrbForm.mtrb_inrate = helper.calcPrice(mtrbForm.mtrb_inrate, 4, 10000)" placeholder="请填写增值税率" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="起订量" prop="mtrb_moq">
                  <el-input v-model="mtrbForm.mtrb_moq" @input="mtrbForm.mtrb_moq = helper.keepTNum1(mtrbForm.mtrb_moq)" maxlength="10" placeholder="请填写起订量" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="24">
              <el-form-item label="备注" prop="mtrb_remark">
                <el-input maxlength="255" v-model="mtrbForm.mtrb_remark" type="textarea" :rows="4" show-word-limit placeholder="请填写备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="8">
          <el-form-item label="材料主图" prop="imge_id">
            <div class="vd_dis">
              <div>
                <el-upload class="upload-demo" drag :action="uploadUrl" :data="dataBody" :show-file-list="false"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" ref="uploadMutiple">
                  <imgPopover v-if="mtrbForm.imge_id" :imgeUrl="mtrbForm.imge_url" :imgeUrlO="mtrbForm.imge_urlO">
                  </imgPopover>
                  <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                </el-upload>
                <span>大小不能超过10MB</span>
              </div>
              <div id="preview" @paste="handlePaste" class="vd_flex_right">
                <span><i class="el-icon-s-opportunity" style="color:#FB894C"></i>点击此处粘贴</span>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { mtrbAPI } from "@api/modules/mtrb";
import { imgeAPI } from "@api/modules/imge";
import { suppAPI } from "@api/modules/supp";
import { optnAPI } from "@api/modules/optn";
// import { settAPI } from '@/api/modules/sett'
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import helper from "@assets/js/helper";
import imgPopover from "@/views/component/imgPopover";

export default {
  name: "MtrbAddMain",
  components: {
    addHeader,
    inputUser,
    imgPopover,
  },
  data() {
    return {
      rules: {
        imge_id: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        mtrb_type: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        supp_id: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        mtrb_price: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        mtrb_unit: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        mtrb_inrate: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
      },
      mtrbForm: {
        mtrb_no: null,
        mtrb_id: null,
        mtrb_name: null,
        mtrb_type: null,
        mtrb_spec: null,
        mtrb_price: null,
        supp_id: null,
        mtrb_unit: '个',
        mtrb_inrate: null,
        mtrb_moq: null,
        mtrb_remark: null,
        imge_id: null,
        imge_url: "",
        imge_urlO: "",
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 800,
        y: 800,
      },
      mtrbUnitList: [],
      suppList:[],
      mtrbTypeList:[
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
      ],
    }
  },
  watch: {
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.getMtrbRankList()
      this.getSupp()
      this.getSettList()
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            this.$message.warning("请把必填内容补充完整");
            return false;
          }
        });
      }).catch(() => { })
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData()
      let mtrbcopy = JSON.parse(JSON.stringify(this.mtrbForm))
      let mtrbForm = Object.assign(mtrbcopy, staffForm)
      // mtrbForm.mtrb_date = parseInt(new Date(mtrbForm.mtrb_date).getTime() / 1000)
      mtrbForm.personal = this.$cookies.get('userInfo').personal
      post(mtrbAPI.addMtrb, mtrbForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            // const permId = this.$route.query.perm_id
            // console.log(this.$route.query.key);
            const props = this.$route.query
            this.jump('/mtrb_edit', {
              key:
                this.$UrlEncode.encode(JSON.stringify({
                  perm_id: props.perm_id,
                  form_id: res.data.data.form_id
                }))
            } ,
            )
            // this.jump(`/mtrb_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
            this.resetForm('mtrbForm')
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('mtrbForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(() => { })
    },
    // getSettList() {
    //   get(settAPI.getSetts).then(res => {
    //     if (res.data.code === 0) {
    //       let settList = res.data.data.list;
    //       for(let i=0;i<settList.length;i++){
    //         if(settList[i].sett_key === "mtrb_inrate"){
    //           this.mtrbForm.mtrb_inrate = this.helper.haveFour(settList[i].sett_value)
    //         }
    //       }
    //     }else{
    //       this.$message.error(res.data.msg)
    //     }
    //   }).catch(res => {
    //     this.$message.error(res.data.msg)
    //   })
    // },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
            this.suppList = res.data.data
            return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    // 获取稿件等级
    getMtrbRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then((res) => {
          if (res.data.code === 0) {
            this.mtrbUnitList = res.data.data.form.optn_cntt_list
          }
        }).catch(() => { })
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrbForm.imge_id = res.data.imge_id;
        this.mtrbForm.imge_url = this.helper.picUrl(urlEng, "m", updateTime);
        this.mtrbForm.imge_urlO = this.helper.picUrl(urlEng, "l", updateTime);
      } else {
        let mg = res.msg;
        let tp = "error";
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 10
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false
      }
    },
    handlePaste(event) {
      console.log('653152538124',event);
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;
      if (!items || items.length === 0) {
          this.$message.error("当前浏览器不支持本地或请打开图片再复制");
          return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
              file = items[i].getAsFile();
              break;
          }
      }
      if (!file) {
          this.$message.error("粘贴内容非图片");
          return;
      }
      this.$refs.uploadMutiple.handleStart(file);// 将粘贴过来的图片加入预上传队列
      this.$refs.uploadMutiple.submit(); // 提交图片上传队列
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vd_dis{
  display: flex;
  align-items: center;
}
.vd_flex_right{
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}
</style>
